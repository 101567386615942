import classNames from 'classnames';
import {graphql} from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet'

import Layout from './layout';

export default function BlogItem(props) {
  const lang = props.pageContext.language;
  const blog_yaml = props.data.blogYaml;
  const fields = blog_yaml[`fields_${lang}`];
  const html = blog_yaml.fields[`html_${lang}`];

  return (
    <Layout language={lang} nodeData={blog_yaml}>
      <Helmet>
        <title>{fields.title} - {lang === 'en' ? 'Andraž Gregorič Photography' : 'Fotograf Andraž Gregorič'}</title>
        <meta property='og:image' content={blog_yaml.title_image_src}/>
      </Helmet>
      <div className={classNames('main-content', {'short-text': html.replace(/<h1>(.*?)<\/h1>/, '').length < 120})}
           dangerouslySetInnerHTML={{__html: html}}
      />
      <div className='standard-gallery'>
        {blog_yaml.images.map(image => {
          const title = image[`title_${lang}`] || image.title_sl || '';
          const caption = image[`caption_${lang}`];
          return (
            <figure key={image.src}>
              <img src={image.src} alt={title} title={title} />
              {caption ? <figcaption>{caption}</figcaption> : null}
            </figure>
          );
        })}
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    blogYaml(id: {eq: $id}) {
      title_image_src
      fields {
        html_sl
        html_en
      }
      fields_sl {
        slug
        title
        meta_description
        meta_keywords
      }
      fields_en {
        slug
        title
        meta_description
        meta_keywords
      }
      images {
        src
        title_sl
        title_en
        caption_sl
        caption_en
      }
    }
  }
`;
